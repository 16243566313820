import { useState } from "react";
import { Button } from "react-bootstrap";
import { db } from "./Firebase";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { Link } from "react-router-dom";
const AddTestimonialForm = () => {
  const [testimonial, setTestimonial] = useState({
    caption: "",
    paragraph: "",
    customer: "",
    occupation: "",
  });
  const onChange = (e) => {
    setTestimonial({ ...testimonial, [e.target.name]: e.target.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      testimonial.caption === "" ||
      testimonial.paragraph === "" ||
      testimonial.customer === "" ||
      testimonial.occupation === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    try {
      await addDoc(collection(db, "Testimonials"), {
        caption: testimonial.caption,
        paragraph: testimonial.paragraph,
        customer: testimonial.customer,
        occupation: testimonial.occupation,
        created: Timestamp.now(),
      }).then(() => {
        alert("Testimonial Added Successfully!!");
        setTestimonial({
          caption: "",
          paragraph: "",
          customer: "",
          occupation: "",
        });
      });
    } catch (e) {
      alert(e);
      console.log(e);
    }
  }
  return (
    <div>
      <h1>Testimonials</h1>
      <form onSubmit={handleSubmit}>
        <label>Caption</label>
        <textarea
          type="text"
          name="caption"
          value={testimonial.caption || ""}
          onChange={onChange}
        />

        <label>Paragraph</label>
        <textarea
          type="text"
          name="paragraph"
          value={testimonial.paragraph || ""}
          onChange={onChange}
        />
        <label>Customer</label>
        <input
          type="text"
          name="customer"
          value={testimonial.customer || ""}
          onChange={onChange}
        />
        <label>Occupations</label>
        <input
          type="text"
          name="occupation"
          value={testimonial.occupation || ""}
          onChange={onChange}
        />
        {/* <div>
          <label>Do you want to show this on Major Testimonials page</label>
          <select id="type" name="display" value={ testimonial.display || "Select"} onChange={onChange}>
            <option value="Select">Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div> */}

        <Button
          type="submit"
          className="button"
          variant="success"
          onClick={handleSubmit}
        >
          Add
        </Button>
        <p>Navigate Back - </p>
        <Link to="/admin/dashboard/testimonials">Testimonials</Link>
      </form>
    </div>
  );
};

export default AddTestimonialForm;
