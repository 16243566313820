import {
  collection,
  query,
  getDocs,
  where,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref, deleteObject, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../Firebase";

async function handleProjectDelete(Projects, index, innerIndex, getProjects) {
  const confirm = window.confirm(
    "Are you sure you want to delete this Projects?"
  );
  if (confirm) {
    try {
      const ref1 = collection(
        db,
        `Projects-${Projects[index][innerIndex].type}`
      );
      const q = query(
        ref1,
        where("name", "==", Projects[index][innerIndex].name),
        where("location", "==", Projects[index][innerIndex].location)
      );
      const response = await getDocs(q);
      const docid = response.docs[0].id;
      Projects[index][innerIndex].flats?.map(async (flat) => {
        getDownloadURL(ref(storage, `${docid}/${flat.flatid}`)).then(async () => {
          await deleteObject(
            ref(
              storage,
              `${docid}/${flat.flatid}`
            )
          )
        }).catch( () => {
          
        })
      })

        getDownloadURL(ref(storage, `${docid}/${docid}`)).then(async () => {
          await deleteObject(
            ref(
              storage,
              `${docid}/${docid}`
            )
          )
        }).catch((err)=>{
        
        })
        .then(async () => {
          await deleteDoc(
            doc(db, `Projects-${Projects[index][innerIndex].type}`, `${docid}`)
          )
        })
        .then(async () => {
          alert("Project Successfully deleted");
          getProjects();
        })
    } catch (err) {
      console.log(err);
    }
  } else {
    alert("Projects not deleted");
  }
}

export default handleProjectDelete;
