import {
  collection,
  query,
  getDocs,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Firebase";

async function handleEdit(
  allTestimonials,
  updatedTestimonial,
  index,
  innerIndex,
  getTestimonials,
  setIsEditing
) {
  if (
    allTestimonials[index][innerIndex].caption === updatedTestimonial.caption &&
    allTestimonials[index][innerIndex].paragraph ===
      updatedTestimonial.paragraph &&
    allTestimonials[index][innerIndex].customer ===
      updatedTestimonial.customer &&
    allTestimonials[index][innerIndex].occupation ===
      updatedTestimonial.occupation
  ) {
    alert("No changes made");
  } else {
    const confirm = window.confirm("Are you sure you want to edit this?");
    if (confirm) {
      try {
        const Idref = collection(db, "Testimonials");
        const q = query(
          Idref,
          where("caption", "==", allTestimonials[index][innerIndex].caption),
          where("customer", "==", allTestimonials[index][innerIndex].customer)
        );
        const ans = await getDocs(q);
        const newid = ans.docs[0].id;
        await updateDoc(doc(db, "Testimonials", `${newid}`), {
          caption: updatedTestimonial.caption,
          paragraph: updatedTestimonial.paragraph,
          customer: updatedTestimonial.customer,
          occupation: updatedTestimonial.occupation,
        }).then(() => {
          getTestimonials();
          setIsEditing(-1);
          alert("Testimonials edited successfully");
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
}

export default handleEdit;