import {
    collection,
    query,
    getDocs,
    where,
    doc,
    deleteDoc,
} from "firebase/firestore";
import { db } from "../../Firebase";

async function handleDelete(
    allCareers,
    index,
    innerIndex,
    getCareers
) {
    const confirm = window.confirm(
        "Are you sure you want to delete this Career?"
    );
    if (confirm) {
        try {
            const ref1 = collection(db, `Careers`);
            const q = query(
                ref1,
                where("jobTitle", "==", allCareers[index][innerIndex].jobTitle),
            );
            const response = await getDocs(q);
            const id = response.docs[0].id;
            await deleteDoc(doc(db, `Careers`, `${id}`))
                .then(() => {
                    getCareers();
                    alert("Career deleted successfully");
                })
                .catch((err) => {
                    alert(err);
                });
        } catch (err) {
            console.log(err);
        }
    } else {
        alert("Career not deleted");
    }
}

export default handleDelete;
