import {
  collection,
  query,
  getDocs,
  where,
  doc,
  deleteDoc,
  addDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { db, storage } from "../../Firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

async function handleProjectEdit(
  Projects,
  updatedProjects,
  setupdatedProjects,
  index,
  innerIndex,
  getProjects,
  setIsEditing
) {
  if (
    updatedProjects.name === "" ||
    updatedProjects.description === "" ||
    updatedProjects.brochureLink === "" ||
    updatedProjects.location === "" ||
    updatedProjects.reraNumber === "" ||
    updatedProjects.type === "" ||
    updatedProjects.latitude === "" ||
    updatedProjects.longitude === "" ||
    updatedProjects.mainproject === "Select" ||
    updatedProjects.type === "Select" ||
    updatedProjects.highlight === [] ||
    updatedProjects.landmarks === [] ||
    updatedProjects.amentities === [] ||
    updatedProjects.testimonial ===[] ||
    updatedProjects.flats ===[]
  ) {
    alert("Please fill all the fields");
    return;
  }
  if (
    Projects[index][innerIndex] === updatedProjects
  ) {
    alert("No changes made");
    return;
  }
  if (
    updatedProjects.latitude < -90 ||
    updatedProjects.latitude > 90 ||
    updatedProjects.longitude < -180 ||
    updatedProjects.longitude > 180
  ) {
    alert("Either of latitude or longitude empty/inncorrect");
    return;
  } else {
    const confirm = window.confirm("Are you sure you want to edit this?");
    // console.log(updatedProjects);
    if (confirm) {
      const ref1 = collection(
        db,
        `Projects-${Projects[index][innerIndex].type}`
      );
      const q = query(
        ref1,
        where("name", "==", Projects[index][innerIndex].name),
        where("location", "==", Projects[index][innerIndex].location)
      );
      const response = await getDocs(q);
      const docid = response.docs[0].id;
      if (Projects[index][innerIndex].type !== updatedProjects.type) {
        // getDownloadURL()
        await deleteDoc(
          doc(db, `Projects-${Projects[index][innerIndex].type}`, `${docid}`)
        );
        try {
          await addDoc(collection(db, `Projects-${updatedProjects.type}`), {
            name: updatedProjects.name,
            description: updatedProjects.description,
            brochureLink: updatedProjects.brochureLink,
            reraNumber: updatedProjects.reraNumber,
            location: updatedProjects.location,
            latitude: updatedProjects.latitude,
            longitude: updatedProjects.longitude,
            mainproject: updatedProjects.mainproject,
            type: updatedProjects.type,
            landmarks: updatedProjects.landmarks,
            amentities: updatedProjects.amentities,
            highlight: updatedProjects.highlight,
            testimonial: updatedProjects.testimonial,
            created: Timestamp.now(),
          })
            .then(async () => {
              const ref1 = collection(db, `Projects-${updatedProjects.type}`);
              const q = query(
                ref1,
                where("name", "==", updatedProjects.name),
                where("description", "==", updatedProjects.description)
              );
              const response = await getDocs(q);
              const newdocid = response.docs[0].id;
              const storageRef = ref(storage, `${newdocid}/${newdocid}`);
              if (updatedProjects.image) {
                const uploadTask = uploadBytesResumable(
                  storageRef,
                  updatedProjects.mainimage,
                  {
                    contentType: "image/webp",
                  }
                )
                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    const progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case "paused":
                        console.log("Upload is paused");
                        break;
                      case "running":
                        // console.log("Upload is running");
                        break;
                      default:
                        break;
                    }
                  },
                  (error) => {
                    switch (error.code) {
                      case "storage/unauthorized":
                        break;
                      case "storage/canceled":
                        break;
                      case "storage/unknown":
                        break;
                      default:
                        break;
                    }
                  },
                  () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(
                      async (downloadURL) => {
                        try {
                          await updateDoc(
                            doc(db, `Projects-${updatedProjects.type}`, newdocid),
                            {
                              mainimageUrl: downloadURL,
                            }
                          );
                        } catch (err) {
                          console.log(err);
                        }
                      }
                    );
                  }
                );
              }
              
              updatedProjects.flats
                .map(async (data, index) => {
                  try {
                    if (data.image) {
                      const storageRef = ref(storage, `${docid}/${data.flatid}`);
                      const uploadTask = uploadBytesResumable(
                        storageRef,
                        data.image,
                        {
                          contentType: "image/webp",
                        }
                      );
                      uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                          const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                          console.log("Upload is " + progress + "% done");
                          switch (snapshot.state) {
                            case "paused":
                              console.log("Upload is paused");
                              break;
                            case "running":
                              console.log("Upload is running");
                              break;
                            default:
                              break;
                          }
                        },
                        (error) => {
                          switch (error.code) {
                            case "storage/unauthorized":
                              break;
                            case "storage/canceled":
                              break;
                            case "storage/unknown":
                              break;
                            default:
                              break;
                          }
                        },
                        () => {
                          getDownloadURL(uploadTask.snapshot.ref).then(
                            async (downloadURL) => {
                              data.imageUrl = downloadURL;
                            }
                          );
                        }
                      );
                    }
                    delete data.image;
                  } catch (e) {
                    console.log(e);
                  }
                })
            }).then(async () => {
              const ref1 = collection(db, `Projects-${updatedProjects.type}`);
              const q = query(
                ref1,
                where("name", "==", updatedProjects.name),
                where("description", "==", updatedProjects.description)
              );
              const response = await getDocs(q);
              const docid = response.docs[0].id;
              try {
                console.log(updatedProjects.flats);
                await updateDoc(
                  doc(db, `Projects-${updatedProjects.type}`, `${docid}`),
                  { flats: updatedProjects.flats }
                );
              } catch (err) {
                console.log(err);
              }
            })
            .then(() => {
              alert("Project Updated successfully!!");
              getProjects();
              setupdatedProjects({
                name: "",
                description: "",
                brochureLink: "",
                type: "Select",
                mainproject: "false",
                reraNumber: "",
                location: "",
                latitude: 0,
                longitude: 0,
                landmarks: [],
                highlight: [],
                amentities: [],
                flats: [],
                testimonial: [],
              });
            });
        } catch (e) {
          console.log(e);
        }
      }
      
      else {
        var allDocIds = [];
        if (updatedProjects.mainimage) {
          getDownloadURL(ref(storage, `${docid}/${docid}`))
            .then(async () => {
              await deleteObject(ref(storage, `${docid}/${docid}`)).then(
                async () => {
                  const storageRef = ref(storage, `${docid}/${docid}`);
                  const uploadTask = uploadBytesResumable(
                    storageRef,
                    updatedProjects.mainimage,
                    {
                      contentType: "image/webp",
                    }
                  );
                  uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                      const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) *
                        100;
                      // console.log("Upload is " + progress + "% done");
                      switch (snapshot.state) {
                        case "paused":
                          console.log("Upload is paused");
                          break;
                        case "running":
                          // console.log("Upload is running");
                          break;
                        default:
                          break;
                      }
                    },
                    (error) => {
                      switch (error.code) {
                        case "storage/unauthorized":
                          break;
                        case "storage/canceled":
                          break;
                        case "storage/unknown":
                          break;
                        default:
                          break;
                      }
                    },
                    () => {
                      getDownloadURL(uploadTask.snapshot.ref).then(
                        async (downloadURL) => {
                          try {
                            await updateDoc(
                              doc(
                                db,
                                `Projects-${updatedProjects.type}`,
                                docid
                              ),
                              {
                                mainimageUrl: downloadURL,
                              }
                            );
                          } catch (err) {
                            console.log(err);
                          }
                        }
                      );
                    }
                  );
                }
              );
            })
            .catch(async () => {
              const storageRef = ref(storage, `${docid}/${docid}`);
              const uploadTask = uploadBytesResumable(
                storageRef,
                updatedProjects.mainimage,
                {
                  contentType: "image/webp",
                }
              );
              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  // console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      console.log("Upload is paused");
                      break;
                    case "running":
                      // console.log("Upload is running");
                      break;
                    default:
                      break;
                  }
                },
                (error) => {
                  switch (error.code) {
                    case "storage/unauthorized":
                      break;
                    case "storage/canceled":
                      break;
                    case "storage/unknown":
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                      try {
                        await updateDoc(
                          doc(
                            db,
                            `Projects-${updatedProjects.type}`,
                            docid
                          ),
                          {
                            mainimageUrl: downloadURL,
                          }
                        );
                      } catch (err) {
                        console.log(err);
                      }
                    }
                  );
                }
              );
            });
        }
        await updateDoc(
          doc(db, `Projects-${Projects[index][innerIndex].type}`, docid),
          {
            name: updatedProjects.name,
            description: updatedProjects.description,
            brochureLink: updatedProjects.brochureLink,
            reraNumber: updatedProjects.reraNumber,
            location: updatedProjects.location,
            latitude: updatedProjects.latitude,
            longitude: updatedProjects.longitude,
            mainproject: updatedProjects.mainproject,
            type: updatedProjects.type,
            landmarks: updatedProjects.landmarks,
            highlight: updatedProjects.highlight,
            amentities: updatedProjects.amentities,
            testimonial: updatedProjects.testimonial,
          }
        )
          .then(async () => {
            const Data = response.docs[0].data();
            console.group(Data);
            var list = [];
            for (let y = 0; y < updatedProjects.flats?.length; y++){
              var flag = false;
              for (let x = 0; x < Data.flats?.length; x++){
                if (Data.flats[x].title === updatedProjects.flats[y].title) {
                  flag = true;
                  if (updatedProjects.flats[y].image) {
                      console.log(docid)
                    const storageRef = ref(storage, `${docid}/${updatedProjects.flats[y].flatid}`);
                      const uploadTask = uploadBytesResumable(
                        storageRef,
                        updatedProjects.flats[y].image,
                        {
                          contentType: "image/webp",
                        }
                      );
                      uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                          const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                          console.log("Upload is " + progress + "% done");
                          switch (snapshot.state) {
                            case "paused":
                              console.log("Upload is paused");
                              break;
                            case "running":
                              console.log("Upload is running");
                              break;
                            default:
                              break;
                          }
                        },
                        (error) => {
                          switch (error.code) {
                            case "storage/unauthorized":
                              break;
                            case "storage/canceled":
                              break;
                            case "storage/unknown":
                              break;
                            default:
                              break;
                          }
                        },
                        () => {
                          getDownloadURL(uploadTask.snapshot.ref).then(
                            async (downloadURL) => {
                              // console.log(updatedProjects.flats);
                              updatedProjects.flats[y].imageUrl = downloadURL;
                              try {
                                await updateDoc(
                                  doc(db, `Projects-${updatedProjects.type}`, `${docid}`),
                                  { flats: updatedProjects.flats }
                                );
                              } catch (err) {
                                console.log(err);
                              }
                            }
                          );
                        }
                      );
                  }
                  else {
                    try {
                      await updateDoc(
                        doc(db, `Projects-${updatedProjects.type}`, `${docid}`),
                        { flats: updatedProjects.flats }
                      );
                    } catch (err) {
                      console.log(err);
                    }
                  }
                  delete updatedProjects.flats[y].image;
                  list.push(Data.flats[x].flatid);
                }
              }
              if (!flag) {
                // getDownloadURL(ref(storage, `${docid}/${Data.flats[x]}`))
                //   .then(async () => {
                //     await deleteObject(ref(storage, `${docid}/${Data.flats[x].flatid}`));
                //   }).catch(async (err) => {
                //     console.log(err)
                //   })
                if (updatedProjects.flats[y].image) {
                  console.log(docid)
                  const storageRef = ref(storage, `${docid}/${updatedProjects.flats[y].flatid}`);
                  const uploadTask = uploadBytesResumable(
                    storageRef,
                    updatedProjects.flats[y].image,
                    {
                      contentType: "image/webp",
                    }
                  );
                  uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                      const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      console.log("Upload is " + progress + "% done");
                      switch (snapshot.state) {
                        case "paused":
                          console.log("Upload is paused");
                          break;
                        case "running":
                          console.log("Upload is running");
                          break;
                        default:
                          break;
                      }
                    },
                    (error) => {
                      switch (error.code) {
                        case "storage/unauthorized":
                          break;
                        case "storage/canceled":
                          break;
                        case "storage/unknown":
                          break;
                        default:
                          break;
                      }
                    },
                    () => {
                      getDownloadURL(uploadTask.snapshot.ref).then(
                        async (downloadURL) => {
                          // console.log(updatedProjects.flats);
                          updatedProjects.flats[y].imageUrl = downloadURL;
                          try {
                            await updateDoc(
                              doc(db, `Projects-${updatedProjects.type}`, `${docid}`),
                              { flats: updatedProjects.flats }
                            );
                          } catch (err) {
                            console.log(err);
                          }
                        }
                      );
                    }
                  );
                }
                delete updatedProjects.flats[y].image;
              }
            }
            console.log(list);
            for (let x = 0; x < Data.flats?.length; x++){
              if (list.indexOf(Data.flats[x].flatid) === -1) {
                getDownloadURL(ref(storage, `${docid}/${Data.flats[x].flatid}`))
                .then(async () => {
                  await deleteObject(ref(storage, `${docid}/${Data.flats[x].flatid}`));
                }).catch(async () => {
                  
                })
              }
            }
            try {
              await updateDoc(
                doc(db, `Projects-${updatedProjects.type}`, `${docid}`),
                { flats: updatedProjects.flats }
              );
            } catch (err) {
              console.log(err);
            }
            // list.map(async (flatid) => {
            //   
            // })
          })
          // .then(async () => {
          //   for (let x = 0; x < allDocIds.length; x++) {
          //     getDownloadURL(ref(storage, `${docid}/${allDocIds[x]}`))
          //       .then(async () => {
          //         await deleteObject(ref(storage, `${docid}/${allDocIds[x]}`));
          //       }).catch(async () => {
                  
          //       })
          //         await deleteDoc(
          //           doc(
          //             db,
          //             `Projects-${Projects[index][innerIndex].type}/${docid}/Flats`,
          //             `${allDocIds[x]}`
          //           )
          //         );
          //     const i = allDocIds.indexOf(allDocIds[x]);
          //     allDocIds.splice(i, 1);
          //   }
          // })
          .then(() => {
            alert("Project Updated successfully!!");
            getProjects();
            setupdatedProjects({
              name: "",
              description: "",
              brochureLink: "",
              type: "Select",
              mainproject: "false",
              reraNumber: "",
              location: "",
              latitude: 0,
              longitude: 0,
              landmarks: [],
              highlight: [],
              amentities: [],
              flats: [],
              testimonial: [],
            });
          });
      }
    }
  }
}

export default handleProjectEdit;
