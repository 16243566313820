import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button } from "react-bootstrap";
import {
  handlehighlightAdd,
  handlehighlightChange,
  handlehighlightRemove,
  handlelandmarkAdd,
  handlelandmarkChange,
  handlelandmarkRemove,
  handletestimonialChange,
  handletestimonialRemove,
  handletestimonialAdd,
  handleflatsAdd,
  handleflatsChange,
  handleflatsRemove,
  handleamentitiesAdd,
  handleamentitiesRemove,
  handleamentitiesChange,
} from "./Funtions/ProjectHandlers";
import { db, storage } from "./Firebase";
import {
  query,
  getDocs,
  collection,
  addDoc,
  updateDoc,
  doc,
  where,
  Timestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
const AddProjects = () => {
  const [project, setProject] = useState({
    name: "",
    description: "",
    type: "Select",
    mainproject: "false",
    reraNumber: "",
    location: "",
    latitude: 0,
    longitude: 0,
    mainimage: "",
    brochureLink: "",
    landmarks: [],
    highlight: [],
    amentities: [],
    flats: [],
    testimonial: [],
  });
  const onChange = (e) => {
    setProject({ ...project, [e.target.name]: e.target.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      project.name === "" ||
      project.description === "" ||
      project.location === "" ||
      project.type === "Select" ||
      project.mainproject === "Select"
    ) {
      alert("Please fill all the fields");
      return;
    }
    if (
      project.latitude < -90 ||
      project.latitude > 90 ||
      project.longitude < -180 ||
      project.longitude > 180
    ) {
      alert("Either of latitude or longitude empty/inncorrect");
      return;
    }
    try {
      console.log(project);
      await addDoc(collection(db, `Projects-${project.type}`), {
        name: project.name,
        description: project.description,
        brochureLink: project.brochureLink,
        reraNumber: project.reraNumber,
        location: project.location,
        latitude: project.latitude,
        longitude: project.longitude,
        mainproject: project.mainproject,
        type: project.type,
        landmarks: project.landmarks,
        amentities: project.amentities,
        highlight: project.highlight,
        testimonial: project.testimonial,
        // flats: project.flats,
        created: Timestamp.now(),
      })
        .then(async () => {
          const ref1 = collection(db, `Projects-${project.type}`);
          const q = query(
            ref1,
            where("name", "==", project.name),
            where("description", "==", project.description)
          );
          const response = await getDocs(q);
          const docid = response.docs[0].id;
          if (project.mainimage) {
            const storageRef = ref(storage, `${docid}/${docid}`);
            const uploadTask = uploadBytesResumable(
              storageRef,
              project.mainimage,
              {
                contentType: "image/webp",
              }
            );
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    console.log("Upload is paused");
                    break;
                  case "running":
                    // console.log("Upload is running");
                    break;
                  default:
                    break;
                }
              },
              (error) => {
                switch (error.code) {
                  case "storage/unauthorized":
                    break;
                  case "storage/canceled":
                    break;
                  case "storage/unknown":
                    break;
                  default:
                    break;
                }
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then(
                  async (downloadURL) => {
                    // console.log("File available at", downloadURL);
                    try {
                      await updateDoc(
                        doc(db, `Projects-${project.type}`, docid),
                        {
                          mainimageUrl: downloadURL,
                        }
                      );
                    } catch (err) {
                      console.log(err);
                    }
                  }
                );
              }
            );
          }
          project.flats
            .map(async (data, index) => {
              try {
                if (data.image) {
                  console.log(docid)
                  const storageRef = ref(storage, `${docid}/${data.flatid}`);
                  const uploadTask = uploadBytesResumable(
                    storageRef,
                    data.image,
                    {
                      contentType: "image/webp",
                    }
                  );
                  uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                      const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      console.log("Upload is " + progress + "% done");
                      switch (snapshot.state) {
                        case "paused":
                          console.log("Upload is paused");
                          break;
                        case "running":
                          console.log("Upload is running");
                          break;
                        default:
                          break;
                      }
                    },
                    (error) => {
                      switch (error.code) {
                        case "storage/unauthorized":
                          break;
                        case "storage/canceled":
                          break;
                        case "storage/unknown":
                          break;
                        default:
                          break;
                      }
                    },
                    () => {
                      getDownloadURL(uploadTask.snapshot.ref).then(
                        async (downloadURL) => {
                          console.log(data);
                          data.imageUrl = downloadURL;
                          try {
                            await updateDoc(
                              doc(db, `Projects-${project.type}`, `${docid}`),
                              { flats: project.flats }
                            );
                          } catch (err) {
                            console.log(err);
                          }
                        }
                      );
                    }
                  );
                }
                delete data.image;
                // return;
              } catch (e) {
                console.log(e);
              }
            })
          console.log("222");

         
        }).then(async () => {
          
          project.testimonial.forEach(async (review) => {
            const Idreference = collection(db, "Testimonials");
            const q2 = query(
              Idreference,
              where("caption", "==", review.caption),
              where("paragraph", "==", review.paragraph),
              where("customer", "==", review.customer),
              where("occupation", "==", review.occupation)
            );
            const responed = await getDocs(q2);
            const resID = responed.docs[0]?.id || "";
            if (resID === "") {
              if (review.display === "true") {
                try {
                  await addDoc(collection(db, "Testimonials"), {
                    caption: review.caption,
                    paragraph: review.paragraph,
                    customer: review.customer,
                    occupation: review.occupation,
                    created: Timestamp.now(),
                  });
                } catch (e) {
                  alert(e);
                  console.log(e);
                }
              }
              // else {
              //   console.log("false");
              // }
            }
          });
        })
        .then(() => {
          alert("Project Uploaded successfully!!");
          setProject({
            name: "",
            description: "",
            brochureLink: "",
            type: "Select",
            mainproject: "false",
            reraNumber: "",
            location: "",
            latitude: 0,
            longitude: 0,
            landmarks: [],
            highlight: [],
            amentities: [],
            flats: [],
            testimonial: [],
          });
        });
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div className="addprojects">
      <form onSubmit={handleSubmit} className="addprojects-form">
        <div>
          <label>Project Name</label>
          <input
            type="text"
            name="name"
            value={project.name || ""}
            onChange={onChange}
          />
        </div>

        <div>
          <label>Project Type</label>
          <select
            id="type"
            name="type"
            value={project.type || ""}
            onChange={onChange}
          >
            <option value="Select">Select</option>
            <option value="Completed">Completed</option>
            <option value="Ongoing">Ongoing</option>
            <option value="Upcoming">Upcoming</option>
          </select>
        </div>
        <div>
          <label>Want to highlight this project?</label>
          <select
            id="mainproject"
            name="mainproject"
            value={project.mainproject || ""}
            onChange={onChange}
          >
            <option value="Select">Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        <div>
          <label>Project Description</label>
          <textarea
            rows="4"
            cols="24"
            name="description"
            form="usrform"
            value={project.description || ""}
            onChange={onChange}
          ></textarea>
        </div>

        <div>
          <label>Brochure Link</label>
          <input
            rows="4"
            cols="24"
            name="brochureLink"
            value={project.brochureLink || ""}
            onChange={onChange}
          ></input>
        </div>

        <div>
          <label>Project RERA No.</label>
          <input
            type="text"
            name="reraNumber"
            value={project.reraNumber || ""}
            onChange={onChange}
          />
        </div>
        <div>
          <label>Project Location</label>
          <input
            type="text"
            name="location"
            value={project.location || ""}
            onChange={onChange}
          />
        </div>
        <div>
          <label>latitude</label>
          <input
            type="number"
            name="latitude"
            value={project.latitude || ""}
            onChange={onChange}
          />
        </div>
        <div>
          <label>longitude</label>
          <input
            type="number"
            name="longitude"
            value={project.longitude || ""}
            onChange={onChange}
          />
        </div>
        <div>
          <input
            type="file"
            className="itemList-input"
            name="mainimage"
            onChange={(e) => {
              setProject({ ...project, mainimage: e.target.files[0] });
            }}
          />
        </div>
        <div>
          <table className="item__table">
            <thead>
              <tr className="item-heading">
                <th className="title-item--index">#</th>
                <th className="title-item title">landmarks</th>
              </tr>
            </thead>
            <tbody>
              {project.landmarks.map((x, i) => {
                return (
                  <tr key={i} className="item-row">
                    <td className="title-input invoice__item--index">
                      {/* {index === i ? */}
                      <div
                        onClick={() =>
                          handlelandmarkRemove(
                            project,
                            i,
                            project.landmarks,
                            setProject,
                            handlelandmarkAdd(
                              project,
                              project.landmarks,
                              setProject
                            )
                          )
                        }
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </div>
                      {/* : <div>
                        {i}
                      </div>
                    } */}
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="caption"
                        value={project.landmarks[i]}
                        onChange={(e) =>
                          handlelandmarkChange(
                            e,
                            project,
                            i,
                            project.landmarks,
                            setProject
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="itemAdd-btn">
            {project.lenght !== 1 && (
              <Button
                onClick={() =>
                  handlelandmarkAdd(project, project.landmarks, setProject)
                }
              >
                Add landmarks
              </Button>
            )}
          </div>
        </div>

        <div>
          <table className="item__table">
            <thead>
              <tr className="item-heading">
                <th className="title-item--index">#</th>
                <th className="title-item title">Distance</th>
              </tr>
            </thead>
            <tbody>
              {project.highlight.map((x, i) => {
                return (
                  <tr key={i} className="item-row">
                    <td className="title-input invoice__item--index">
                      {/* {index === i ? */}
                      <div
                        onClick={() =>
                          handlehighlightRemove(
                            project,
                            i,
                            project.highlight,
                            setProject,
                            handlehighlightAdd(
                              project,
                              project.highlight,
                              setProject
                            )
                          )
                        }
                      >
                        <i class="fa-solid fa-trash-can"></i>
                      </div>
                      {/* : <div>
                        {i}
                      </div>
                    } */}
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="caption"
                        value={project.highlight[i]}
                        onChange={(e) =>
                          handlehighlightChange(
                            e,
                            project,
                            i,
                            project.highlight,
                            setProject
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="itemAdd-btn">
            {project.lenght !== 1 && (
              <Button
                onClick={() =>
                  handlehighlightAdd(project, project.highlight, setProject)
                }
              >
                Add Distance
              </Button>
            )}
          </div>
        </div>

        <div>
          <table className="item__table">
            <thead>
              <tr className="item-heading">
                <th className="title-item--index">#</th>
                <th className="title-item title">Amentities</th>
              </tr>
            </thead>
            <tbody>
              {project.amentities?.map((x, i) => {
                return (
                  <tr key={i} className="item-row">
                    <td className="title-input invoice__item--index">
                      {/* {index === i ? */}
                      <div
                        onClick={() =>
                          handleamentitiesRemove(
                            project,
                            i,
                            project.amentities,
                            setProject,
                            handleamentitiesAdd(
                              project,
                              project.amentities,
                              setProject
                            )
                          )
                        }
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </div>
                      {/* : <div>
                        {i}
                      </div>
                    } */}
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="caption"
                        value={project.amentities[i]}
                        onChange={(e) =>
                          handleamentitiesChange(
                            e,
                            project,
                            i,
                            project.amentities,
                            setProject
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="itemAdd-btn">
            {project.lenght !== 1 && (
              <Button
                onClick={() =>
                  handleamentitiesAdd(project, project.amentities, setProject)
                }
              >
                Add amentities
              </Button>
            )}
          </div>
        </div>

        <div>
          <table className="item__table">
            <thead>
              <tr className="item-heading">
                <th className="title-item--index">#</th>
                <th className="title-item title">title</th>
                <th className="title-item title">description</th>
                <th className="title-item title">Type</th>
                <th className="title-item title">Image</th>
              </tr>
            </thead>
            <tbody>
              {project.flats.map((x, i) => {
                return (
                  <tr key={i} className="item-row">
                    <td className="title-input invoice__item--index">
                      {/* {index === i ? */}
                      <div
                        onClick={() =>
                          handleflatsRemove(
                            project,
                            i,
                            project.flats,
                            setProject,
                            handleflatsAdd(project, project.flats, setProject)
                          )
                        }
                      >
                        <i class="fa-solid fa-trash-can"></i>
                      </div>
                      {/* : <div>
                        {i}
                      </div>
                    } */}
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="title"
                        value={project.flats[i].title}
                        onChange={(e) =>
                          handleflatsChange(e, project, i, setProject)
                        }
                      />
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="description"
                        value={project.flats[i].description}
                        onChange={(e) =>
                          handleflatsChange(e, project, i, setProject)
                        }
                      />
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="type"
                        value={project.flats[i].type}
                        onChange={(e) =>
                          handleflatsChange(e, project, i, setProject)
                        }
                      />
                    </td>
                    <td className="title-input">
                      <input
                        type="file"
                        className="itemList-input"
                        name="image"
                        onChange={(e) => {
                          const flat = [...project.flats];
                          flat[i][e.target.name] = e.target.files[0];
                          setProject({ ...project, flats: flat });
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="itemAdd-btn">
            {project.lenght !== 1 && (
              <Button
                onClick={() =>
                  handleflatsAdd(project, project.flats, setProject)
                }
              >
                Add flats
              </Button>
            )}
          </div>
        </div>
        <div>
          <table className="item__table">
            <thead>
              <tr className="item-heading">
                <th className="title-item--index">#</th>
                <th className="title-item title">Caption</th>
                <th className="title-item title">Paragraph</th>
                <th className="title-item title">Customer</th>
                <th className="title-item title">Customer's Occupations</th>
                <th className="title-item title">Add to Main?</th>
              </tr>
            </thead>
            <tbody>
              {project.testimonial.map((x, i) => {
                return (
                  <tr key={i} className="item-row">
                    <td className="title-input invoice__item--index">
                      {/* {index === i ? */}
                      <div
                        onClick={() =>
                          handletestimonialRemove(
                            project,
                            i,
                            project.testimonial,
                            setProject,
                            handletestimonialAdd(
                              project,
                              project.testimonial,
                              setProject
                            )
                          )
                        }
                      >
                        <i class="fa-solid fa-trash-can"></i>++
                      </div>
                      {/* : <div>
                        {i}
                      </div>
                    } */}
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="caption"
                        value={project.testimonial[i].caption}
                        onChange={(e) =>
                          handletestimonialChange(e, project, i, setProject)
                        }
                      />
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="paragraph"
                        value={project.testimonial[i].paragraph}
                        onChange={(e) =>
                          handletestimonialChange(e, project, i, setProject)
                        }
                      />
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="customer"
                        value={project.testimonial[i].customer}
                        onChange={(e) =>
                          handletestimonialChange(e, project, i, setProject)
                        }
                      />
                    </td>
                    <td className="title-input">
                      <input
                        className="itemList-input"
                        name="occupation"
                        value={project.testimonial[i].occupation}
                        onChange={(e) =>
                          handletestimonialChange(e, project, i, setProject)
                        }
                      />
                    </td>
                    <td className="title-input">
                      <select
                        name="display"
                        value={project.testimonial[i].display || "Select"}
                        onChange={(e) =>
                          handletestimonialChange(e, project, i, setProject)
                        }
                      >
                        <option value="Select">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="itemAdd-btn">
            {project.lenght !== 1 && (
              <Button
                onClick={() =>
                  handletestimonialAdd(project, project.testimonial, setProject)
                }
              >
                Add testimonial
              </Button>
            )}
          </div>
        </div>

        <Button
          type="submit"
          variant="success"
          className="button"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddProjects;
