import { Routes, Route, BrowserRouter } from "react-router-dom"
import React, { Suspense, lazy } from 'react';
// import Homepage from './HomePage/Homepage';
// import AboutUs from './AboutUs/AboutUs';
import './App.css';
import "./css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect, useState } from "react";
import { db } from "./Admin/Firebase";
import {
  query,
  getDocs,
  collection,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import AdminIndex from "./Admin/AdminIndex";
import ScrollToTop from "./ScrollToTop";

const Process = lazy(() => import("./Process/Process"));
const Contactus = lazy(() => import("./Contactus/Contactus"));
const Career = lazy(() => import("./Career/Career"));
const HomePage = lazy(() => import('./HomePage/Homepage'));
const AboutUs = lazy(() => import('./AboutUs/AboutUs'));
const Projects = lazy(() => import("./Projects/Projects"));
const Diversification = lazy(() => import('./Diversification/Diversification'));
const MainNavbar = lazy(() => import('./Navbar/MainNavbar'));
const Footer = lazy(() => import('./Footer/Footer'));
const Testimonial = lazy(() => import('./Testimonials/Testimonial'));
const NotFound = lazy(() => import('./NotFound/NotFound'));
const IndividualProject = lazy(() => import('./Projects/IndividualProject'));
function App() {
  const [flats, setFlats] = useState([]);
  const [allDesktopImages, setDesktopImages] = useState([]);
  const [allMobileImages, setMobileImages] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [career, setCareer] = useState([]);
  const [Ongoing, setOngoing] = useState([]);
  const [Highlighted, setHighlighted] = useState([]);
  async function getProject(projectType, setData) {
    try {
      const ref1 = collection(db, `Projects-${projectType}`);
      const query2 = query(ref1, orderBy("created", "desc"));
      const res1 = await getDocs(query2);

      const Data = res1.docs.map((doc) => {
        return { data: doc.data(), id: doc.id };
      });
      var returnData = [
        ...Data.map((item) => {
          return {
            ...item.data,
            // flats: [],
            // id: item.id,
          };
        }),
      ];
      try {
        for (let i = 0; i < returnData.length; i++) {
          if (returnData[i].mainproject === "true") {
            var list = Highlighted;
            list.push(returnData[i]);
            setHighlighted(list);
          }
          // const r1 = collection(
          //   db,
          //   `Projects-${projectType}/${returnData[i].id}/Flats`
          // );
          // const q1 = query(r1, orderBy("type"), limit(3));
          // const response1 = await getDocs(q1);
          // const Data1 = response1.docs.map((doc) => doc.data());
          // returnData[i].flats.push(
          //   ...Data1.map((item) => {
          //     return {
          //       ...item,
          //     };
          //   })
          // );
        }
        setData(returnData);
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getCareer() {
    try {
      const Idref = collection(db, "Careers");
      const quer = query(Idref, where("isOpen", "==", "true"));
      const res = await getDocs(quer);
      var returnData = [...res.docs.map((doc) => doc.data())];
      setCareer(returnData);
    } catch (error) {
      console.log(error)
    }
  }

  async function getTestimonial() {
    try {
      const Idref = collection(db, "Testimonials");
      const res = await getDocs(query(Idref, orderBy("created", "desc"), limit(5)));
      var returnData = [...res.docs.map((doc) => doc.data())];
      setTestimonial(returnData);
    } catch (error) {
      console.log(error)
    }
  }

  async function getImages(category, setData) {
    try {
      const ref1 = collection(db, `homepage-${category}`);
      const q = query(ref1, orderBy("created", "desc"));
      const res = await getDocs(q);
      const Images = res.docs.map((doc) => doc.data());
      setData(Images);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function getFlats() {
    try {
      const ref1 = collection(db, `Projects-Completed`);
      const q = query(ref1, orderBy("created", "desc"), limit(3));
      const res = await getDocs(q);
      const Data1 = res.docs.map((doc) => doc.data());
      console.log(Data1);
      try {
          var returnData = [
            ...Data1.map((item) => {
              return {
                ...item,
              };
            }),
          ];
        setFlats(returnData);
        console.log(Data1);
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.log(err);
      }
    }

  useEffect(() => {
    getFlats();
    getProject("Ongoing", setOngoing);
    getImages("mobile", setMobileImages);
    getImages("desktop", setDesktopImages);
    getTestimonial();
    getCareer();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
          <Suspense fallback={<div>Loading...</div>}>
          <ScrollToTop/>
        <Routes>
          <Route path="/admin/*" element={<AdminIndex/>} />
          <Route
            path="/"
            element={
              <>
                <MainNavbar />
                <HomePage DesktopImages={allDesktopImages} MobileImages={allMobileImages} Projects={Highlighted} />
                <Footer flats={flats} projects={Highlighted} />
              </>
            }
          />
          <Route
            path="/aboutus"
            element={
              <>
                <MainNavbar />
                <AboutUs />
                <Footer flats={flats} projects={Highlighted} />
              </>
            }
          />
          <Route
            path="/diversification"
            element={
              <>
                <MainNavbar />
                <Diversification />
                <Footer flats={flats} projects={Highlighted} />
              </>
            }
          />
          <Route
            path="/projects"
            element={
              <>
                <MainNavbar />
                <Projects />
                <Footer flats={flats} projects={Highlighted} />
              </>
            }
          />
          <Route
              path="/individual-project"
            element={
              <>
                <MainNavbar />
                <IndividualProject />
                <Footer flats={flats} projects={Ongoing} />
              </>
            }
          />
          <Route
            path="/process"
            element={
              <>
                <MainNavbar />
                <Process/>
                <Footer flats={flats} projects={Ongoing} />
              </>
            }
          />
          <Route
            path="/testimonials"
            element={
              <>
                <MainNavbar />
                <Testimonial testimonial={testimonial} />
                <Footer flats={flats} projects={Ongoing} />
              </>
            }
          />
          <Route
          exact={true}
            path="/career"
            element={
              <>
                <MainNavbar />
                <Career career={career} />
                <Footer flats={flats} projects={Ongoing} />
              </>
            }
          />
          <Route
          exact={true}
            path="/contactus"
            element={
              <>
                <MainNavbar />
                <Contactus />
                <Footer flats={flats} projects={Ongoing} />
              </>
            }
            />
            <Route path="*" element={<NotFound />} />
        </Routes>
       
          </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
