import React from 'react'
import { Navbar, Container, Nav } from "react-bootstrap";
import { signOut } from "firebase/auth";
import { auth } from './Firebase';
import { Link } from 'react-router-dom';
const AdminNavbar = ({ Signout }) => {
  return (
    <div className="adminNavbar">
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand><Link to="/admin/dashboard">Admin Dashboard</Link></Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link><Link to="/admin/dashboard">Home</Link></Nav.Link>
            <Nav.Link><Link to="/admin/dashboard/projects">Projects</Link></Nav.Link>
            <Nav.Link><Link to="/admin/dashboard/testimonials">Testimonials</Link></Nav.Link>
            <Nav.Link><Link to="/admin/dashboard/career">Career</Link></Nav.Link>
            <Nav.Link onClick={() => { const confirm = window.confirm("Do you want Sign Out?"); if (confirm) { Signout(); signOut(auth)}}}>Logout</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default AdminNavbar;