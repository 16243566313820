import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  getDocs,
} from "firebase/firestore";
import { Card, Button } from "react-bootstrap";
import { db } from "./Firebase";
import handleDelete from "./Funtions/TestimonialsFn/DeleteTestimonials";
import handleEdit from "./Funtions/TestimonialsFn/EditTestimonials";
import { Link } from "react-router-dom";

const Testimonials = () => {
  const [allTestimonials, setallTestimonials] = useState([]);
  const [isEditing, setIsEditing] = useState(-1);
  const [isEditingInner, setIsEditingInner] = useState(-1);
  const [updatedTestimonial, setupdatedTestimonial] = useState({
    caption: "",
    paragraph: "",
    customer: "",
    occupation: "",
  });

  async function getTestimonials() {
    try {
      const reference = query(collection(db, "Testimonials"));
      const res = await getDocs(reference);
      const Images = res.docs.map((doc) => doc.data());
      const returnData = [
        Images.map((item) => {
          return {
            ...item,
          };
        }),
      ];
      setallTestimonials(returnData);
    } catch (error) {
      console.log("error", error);
    }
  }
  useEffect(() => {
    getTestimonials();
  }, []);
  function render() {
    var index = 0;
    var innerIndex = 0;
    var globalIndex = 0;
    var returnArray = [];
    allTestimonials.forEach((item) => {
      innerIndex = 0;
      item.forEach((item1) => {
        returnArray.push(
          returnCard(
            (globalIndex = globalIndex),
            (index = index),
            (innerIndex = innerIndex),
            (item = item1)
          )
        );
        innerIndex++;
        globalIndex++;
      });
      index++;
    });
    return returnArray;
  }
  const onChange = (e) => {
    setupdatedTestimonial({
      ...updatedTestimonial,
      [e.target.name]: e.target.value,
    });
  };
  function returnCard(globalIndex, index, innerIndex, item) {
    return (
      <Card
        key={globalIndex}
        className="inline"
        style={{
          width: "80vw",
          display: "inline-block",
        }}
      >
        <Card.Body>
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="text"
              name="caption"
              value={
                updatedTestimonial.caption
              }
              onChange={onChange}
            />
          ) : (
            <Card.Title>{item.caption}</Card.Title>
          )}

          {isEditing === index && isEditingInner === innerIndex ? (
            <textarea
              type="text"
              name="paragraph"
              value={
                updatedTestimonial.paragraph
              }
              onChange={onChange}
            />
          ) : (
            <Card.Text>{item.paragraph}</Card.Text>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="text"
              name="customer"
              value={
                updatedTestimonial.customer
              }
              onChange={onChange}
            />
          ) : (
            <Card.Text>{item.customer}</Card.Text>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="text"
              name="occupation"
              value={
                updatedTestimonial.occupation
              }
              onChange={onChange}
            />
          ) : (
            <Card.Text>{item.occupation}</Card.Text>
          )}
          <br />
        </Card.Body>

        <Card.Body>
          {isEditing !== index && isEditingInner !== innerIndex ? (
            <Button
              varint="primary"
              className="button"
              onClick={(e) => {
                e.preventDefault();
                setIsEditingInner(innerIndex);
                setIsEditing(index);
                setupdatedTestimonial(item);
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              variant="primary"
              className="button"
              onClick={() => {
                setIsEditingInner(-1);
                setIsEditing(-1);
              }}
            >
              Cancel
            </Button>
          )}
          {isEditing === index && isEditingInner === innerIndex ? (
            <Button
              className="button"
              onClick={(e) => {
                handleEdit(
                  allTestimonials,
                  updatedTestimonial,
                  index,
                  innerIndex,
                  getTestimonials,
                  setIsEditing
                )
              }}
              variant="success"
            >
              Save
            </Button>
          ) : null}
          <Button
            className="button"
            onClick={() =>
              handleDelete(allTestimonials, index, innerIndex, getTestimonials)
            }
            variant="danger"
          >
            Delete
          </Button>
        </Card.Body>
      </Card>
    );
  }
  return (
    <>
      <Link
        to="/admin/dashboard/addtestimonial"><button className="addnew">
        ++
      </button></Link>
      <div className="dashboard-container">{render()}</div>
    </>
  );
};

export default Testimonials;
