import { Navigate, useNavigate } from "react-router-dom";
import { db, auth } from "./Firebase"
import {getDoc, doc } from "firebase/firestore";
import {  GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Button} from "react-bootstrap";

const google_provider = new GoogleAuthProvider();

export default function Login({user, setSignIn}) {
  let navigate = useNavigate();
  const SignInwithFirebase = (e) => {
    e.preventDefault()
    signInWithPopup(auth, google_provider)
      .then(async () => {
        const docSnap = await getDoc(doc(db, "Management", auth?.currentUser?.email));
        try {
          if (docSnap.exists()) {
            setSignIn();
            navigate("/admin/dashboard/");
          }
          else {
            alert("Email Id is not authorised to access");
            window.location.href = "/";
          }
        } catch (err) {
          console.log(err)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
    return (
      <div>
        <div>Login using Authenticated email id.</div>
        <div>
          <Button onClick={SignInwithFirebase}>
            SIGN IN WITH GOOGLE
          </Button>
        </div>
      </div>
    );
}