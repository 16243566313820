import React, { useEffect, useState } from "react";
import { db, storage } from "./Firebase";
import {
  updateDoc,
  setDoc,
  doc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const AddImagesForm = () => {
  const [data, setData] = useState({
    title: "",
    description: "",
    image: "",
    url: "",
    category: "Select",
  });
    const [loading, setloading] = useState(false);
    const [uploadProgress, setprogress] = useState(0);

  async function afterUpload(url) {
    try {
      const Idref = collection(db, `${data.category}`);
      const q = query(
        Idref,
        where("title", "==", data.title),
        where("description", "==", data.description)
      );
      const ans = await getDocs(q);
      const id = ans.docs[0].id;
      await setDoc(
        doc(db, `${data.category}`, `${id}`),
        { imageUrl: url },
        { merge: true }
      );
      await updateDoc(doc(db, `${data.category}`, `${id}`), {
        title: data.title,
        description: data.description,
        imageUrl: url,
        id: id,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSubmit() {
    if (
      data.title === "" ||
      data.image === null ||
      data.category === "Select"
    ) {
      alert("Please add nessecary fields");
      return;
    }
    try {
      const doc = await addDoc(collection(db, data.category), {
        title: data.title,
        description: data.description,
        created: Timestamp.now(),
      });
      if (doc) {
        if (data.image) {
          const refe = collection(db, data.category);
          const q = query(
            refe,
            where("title", "==", data.title),
            where("description", "==", data.description)
          );
          const response = await getDocs(q);
          const Fileid = response.docs[0].id;
          if (Fileid) {
            if (!data.image) return;
            const storageRef = ref(storage, `/${data.category}/${Fileid}.png`);
            const uploadTask = uploadBytesResumable(storageRef, data.image, {
              contentType: "image/png",
            });
            setloading(true)
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setprogress(progress)
                // console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    console.log("Upload is paused");
                    break;
                  case "running":
                    // console.log("Upload is running");
                    break;
                  default:
                    break;
                }
              },
              (error) => {
                switch (error.code) {
                  case "storage/unauthorized":
                    break;
                  case "storage/canceled":
                    break;
                  case "storage/unknown":
                    break;
                  default:
                    break;
                }
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  setData({ ...data, url: downloadURL });
                  // console.log("File available at", downloadURL);
                  afterUpload(downloadURL).then(() => {
                    alert("Image Uploaded Successfully!!");
                    setloading(false);
                    setData({
                      title: "",
                      description: "",
                      image: "",
                      url: "",
                      category: "Select",
                    });
                  })
                });
              }
            );
          }
        }
      }
    } catch (e) {
      alert("Error in adding Image");
      console.log(e);
    }
  }
  /*   ----------------Authentication ------------*/
  // function checkIfUser() {
  //   if (localStorage.getItem("user") === null) {
  //     return false;
  //   }
  //   return true;
  // }

  // useEffect(() => {
  //   if (!checkIfUser()) {
  //     window.location.href = "/admin/";
  //   }
  // }, []);

  /*   ----------------other operations------------*/
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="add-page">
        <h1>Add Image</h1>

        <label>Title</label>
        <input
          type="text"
          name="title"
          placeholder="Title"
          value={data.title || ""}
          onChange={onChange}
        />

        <label>Description</label>
        <input
          type="text"
          name="description"
          placeholder="description"
          value={data.description || ""}
          onChange={onChange}
        />

        <label>Image</label>
        <input
          type="file"
          name="image"
          placeholder="Image"
          onChange={(e) => setData({ ...data, image: e.target.files[0]})}
        />

        <label>Category</label>
        <select name="category" value={data.category || "Select"} onChange={onChange}>
          <option value="Select">Select</option>
          <option value="homepage-desktop">Homepage Desktop</option>
          <option value="homepage-mobile">Homepage Mobile</option>
        </select>
        <br />
        {loading? <progress value={uploadProgress} max="100"/>:<></>}
        <button className="submit" type="submit" onClick={handleSubmit}>
          Submit
        </button>
      </div>

      <div></div>
    </>
  );
};

export default AddImagesForm;
