import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomeAdmin from './HomeAdmin';
import ProjectsAdmin from './ProjectsAdmin';
import AddImagesForm from "./AddImagesForm"
import Testimonials from './TestimonialsAdmin';
import AddTestimonialForm from './AddTestimonialForm';
import AddProjects from './AddProjects';
import PrivateRoutes from '../PrivateRoute';
import AddCareer from './AddCareer';
import Careers from './CareersAdmin';
const Dashboard = ({user}) => {
  return (
    <div className="dashboard">
      <Routes>
        <Route element={<PrivateRoutes user={user} />}>
        <Route path="/"   element={<HomeAdmin />} />
        <Route path="/addhomeimages"   element={<AddImagesForm />} />
        <Route path="/projects"   element={<ProjectsAdmin />} />
        <Route path="/addprojects"   element={<AddProjects />} />
        <Route path="/addtestimonial"   element={<AddTestimonialForm />} />
        <Route path="/career"   element={<Careers />} />
        <Route path="/addCareer"   element={<AddCareer />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Route>
      </Routes>
    </div>
  );
}

export default Dashboard