import {
    collection,
    query,
    getDocs,
    where,
    doc,
    updateDoc,
    Timestamp,
} from "firebase/firestore";
import { db } from "../../Firebase";

async function handleEdit(
    allCareers,
    updatedCareer,
    index,
    innerIndex,
    getCareers,
    setIsEditing
) {
    if (
        allCareers[index][innerIndex].jobTitle === updatedCareer.jobTitle &&
        allCareers[index][innerIndex].description ===
        updatedCareer.description &&
        allCareers[index][innerIndex].customerisOpen ===
        updatedCareer.customerisOpen
    ) {
        alert("No changes made");
    } else {
        const confirm = window.confirm("Are you sure you want to edit this?");
        console.log(allCareers);
        console.log(updatedCareer);
        if (confirm) {
            try {
                const Idref = collection(db, "Careers");
                const q = query(
                    Idref,
                    where("jobTitle", "==", allCareers[index][innerIndex].jobTitle),
                );
                const ans = await getDocs(q);
                const newid = ans.docs[0].id;
                console.log(ans.docs);
                await updateDoc(doc(db, "Careers", `${newid}`), {
                    jobTitle: updatedCareer.jobTitle,
                    description: updatedCareer.description,
                    isOpen: updatedCareer.isOpen,
                    created: Timestamp.now(),
                }).then(() => {
                    getCareers();
                    setIsEditing(-1);
                    alert("Career edited successfully");
                });
            } catch (e) {
                console.log('error'+e);
            }
        }
    }
}

export default handleEdit;