import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import SignUp from "./SignUp";
import Login from "./Login";
import Dashboard from "./Dashboard";
import AdminNavbar from "./Navbar";
import "../css/style.css";
import { db, auth } from "./Firebase"
import { getDoc, doc } from "firebase/firestore";
import PrivateRoutes from "../PrivateRoute";
export default function AdminIndex() {
  const [user, setUser] = useState(false)
  async function UserExists() {
    const docSnap = await getDoc(doc(db, "Management", auth?.currentUser?.email));
    if (docSnap.exists()) {
      setUser(true)
    }
  }

  return (
    <div className="admin">
      <Routes>
        <Route path="/" element={<Login user={user} setSignIn={() => setUser(true)}  />} />
        <Route element={<PrivateRoutes user={user} />}>
          <Route
            path="/dashboard/*"
            element={
              <>
                <AdminNavbar Signout={() => setUser(false)} />
                <Dashboard user={user} />
              </>
            }
          />
          </Route>
      </Routes>
    </div>
  );
}
