import { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { auth, db, storage } from "./Firebase";
import {
  query,
  getDocs,
  collection,
  doc,
  updateDoc,
  deleteDoc,
  where,
  Timestamp,
} from "firebase/firestore";
import { Link } from "react-router-dom";

export default function HomeAdmin() {
  const [image, setImage] = useState(null);
  const [allimages, setallimages] = useState([]);
  const[loading, setloading] = useState(false);
  const[uploadProgress, setprogress] = useState(0);
  // const [allimages, setallimages] = useState([]);
  const [isEditing, setIsEditing] = useState(-1);
  const [isEditingInner, setIsEditingInner] = useState(-1);
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  function returnCard(globalIndex, index, innerIndex, item, progress) {
    return (
      <Card
        key={globalIndex}
        className="inline"
        style={{
          width: "15rem",
          display: "inline-block",
        }}
      >
        {isEditing === index && isEditingInner === innerIndex ? (
          <input
            type="file"
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
        ) : (
          <Card.Img variant="top" src={item.imageUrl} />
        )}
        <Card.Body>
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="text"
              value={title || ""}
              onChange={(e) => setTitle(e.target.value)}
            />
          ) : (
            <Card.Title>{item.title}</Card.Title>
          )}

          {isEditing === index && isEditingInner === innerIndex ? (
            <textarea
              type="text"
              value={description || ""}
              placeholder={item.description}
              onChange={(e) => setdescription(e.target.value)}
            />
          ) : (
            <Card.Text>{item.description}</Card.Text>
          )}
          <br />
          <Card.Text>{item.category}</Card.Text>
        </Card.Body>
        {isEditing === index && isEditingInner === innerIndex  ? (
          loading?<progress value={uploadProgress} max="100"></progress>:<></>
         ) : (
          <></>
        )} 
        <Card.Body>
          {isEditing !== index && isEditingInner !== innerIndex ? (
            <Button
              varint="primary"
              className="button"
              onClick={(e) => {
                e.preventDefault();
                setIsEditingInner(innerIndex);
                setIsEditing(index);
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              variant="primary"
              className="button"
              onClick={() => {
                setIsEditingInner(-1);
                setIsEditing(-1);
              }}
            >
              Cancel
            </Button>
          )}
          {isEditing === index && isEditingInner === innerIndex ? (
            <Button
              className="button"
              onClick={() => handleEdit(index, innerIndex)}
              variant="success"
            >
              Save
            </Button>
          ) : null}
          <Button
            className="button"
            onClick={() => handleDelete(index, innerIndex)}
            variant="danger"
          >
            Delete
          </Button>
        </Card.Body>
      </Card>
    );
  }

  /* Getting Images and Rendering it*/
  async function getImages() {
    try {
      const reference1 = query(collection(db, "homepage-desktop"));
      const res1 = await getDocs(reference1);
      const desktop = res1.docs.map((doc) => doc.data());
      const reference2 = query(collection(db, "homepage-mobile"));
      const res2 = await getDocs(reference2);
      const mobile = res2.docs.map((doc) => doc.data());
      const returnData = [
        desktop.map((item) => {
          return {
            ...item,
            category: "homepage-desktop",
          };
        }),
        mobile.map((item) => {
          return {
            ...item,
            category: "homepage-mobile",
          };
        }),
      ];
      setallimages(returnData);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    // checkIfUserIsLoggedIn();
    getImages();
  }, []);

  function render() {
    var index = 0;
    var innerIndex = 0;
    var globalIndex = 0;
    var returnArray = [];
    allimages.forEach((item) => {
      innerIndex = 0;
      item.forEach((item1) => {
        returnArray.push(
          returnCard(
            (globalIndex = globalIndex),
            (index = index),
            (innerIndex = innerIndex),
            (item = item1),
          )
        );
        innerIndex++;
        globalIndex++;
      });
      index++;
    });
    return returnArray;
  }

  // function checkIfUserIsLoggedIn() {
  //   if (auth.currentUser === null) {
  //     window.location.href = "/admin/";
  //   }
  // }

  /*------ Deleting the Images--------*/
  async function handleDelete(index, innerIndex) {
    const confirm = window.confirm(
      "Are you sure you want to delete this Image?"
    );
    if (confirm) {
      try {
        const ref1 = collection(db, `${allimages[index][innerIndex].category}`);
        const q = query(
          ref1,
          where("title", "==", allimages[index][innerIndex].title),
          where("description", "==", allimages[index][innerIndex].description)
          // where("imageUrl", "==", req.body.imageUrl),
          // where("id", "==", req.body.id)
        );
        const response = await getDocs(q);
        const id = response.docs[0].id;
        await deleteDoc(doc(db, `${allimages[index][innerIndex].category}`, `${id}`));
        await deleteObject(
          ref(storage, `${allimages[index][innerIndex].category}/${id}.png`)
        )
          .then(() => {
            getImages();
            alert("Card deleted successfully");
          })
          .catch((err) => {
            alert(err);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Card not deleted");
    }
  }

  /*------ Editing the Images--------*/
  async function handleEdit(index, innerIndex) {
    if (
      image === null &&
      allimages[index][innerIndex].title === title &&
      allimages[index][innerIndex].description === description 
    ) {
      alert("No changes made");
    } else {
      const confirm = window.confirm(
        "Are you sure you want to edit this allimages?"
      );
      if (confirm) {
        try {
          const changeI = image !== null ? true : false;
          // console.log(changeI)
          try {
            const Idref = collection(db, `${ allimages[index][innerIndex].category }`);
            const q = query(
              Idref,
              where("title", "==", allimages[index][innerIndex].title)
            );
            const ans = await getDocs(q);
            const newid = ans.docs[0].id;
            if (changeI) {
              console.log("delete")
              await deleteObject(
                ref(
                  storage,
                  `${allimages[index][innerIndex].category}/${newid}.png`
                )
              ).then(async () => {
                const storageRef = ref(storage, `${allimages[index][innerIndex].category}/${newid}.png`);
                const uploadTask = uploadBytesResumable(storageRef, image, {
                  contentType: "image/png",
                });
                setloading(true);
                uploadTask
                  .on(
                    "state_changed",
                    (snapshot) => {
                      const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      setprogress(progress);
                      // console.log("Upload is " + progress + "% done");
                      switch (snapshot.state) {
                        case "paused":
                          console.log("Upload is paused");
                          break;
                        case "running":
                          // console.log("Upload is running");
                          break;
                        default:
                          break;
                      }
                      setprogress(progress);
                    },
                    (error) => {
                      switch (error.code) {
                        case "storage/unauthorized":
                          break;
                        case "storage/canceled":
                          break;
                        case "storage/unknown":
                          break;
                        default:
                          break;
                      }
                    },

                    () => {
                      getDownloadURL(uploadTask.snapshot.ref)
                        .then(async (downloadURL) => {
                          await updateDoc(doc(db, `${allimages[index][innerIndex].category}`, `${newid}`), {
                            title: title,
                            description: description,
                            imageUrl: downloadURL,
                            created:Timestamp.now(),
                          });
                        })
                        .then(() => {
                          getImages();
                          setIsEditing(-1);
                          setloading(false);
                          alert("Image edited successfully");
                        });
                    }
                  )
              })
              
                
            } else if (!changeI) {
              await updateDoc(doc(db, `${allimages[index][innerIndex].category}`, `${newid}`), {
                title: title,
                description: description,
              }).then(() => {
                getImages();
                setIsEditing(-1);
                alert("Image edited successfully");
              });
            }
          } catch (e) {
            console.log(e);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        alert("Image not edited");
        setdescription(allimages[index][innerIndex].description);
        setTitle(allimages[index][innerIndex].title);
      }
    }
  }
  useEffect(() => {
    if (isEditing !== -1) {
      setdescription(allimages[isEditing][isEditingInner].description);
      setTitle(allimages[isEditing][isEditingInner].title);
      // setImage(allimages[isEditing][isEditingInner].imageUrl)
    }
  }, [allimages, isEditing, isEditingInner]);

  return (
    <>
      <Link to="/admin/dashboard/addhomeimages"><button className="addnew">
        ++
      </button></Link>
      <div className="dashboard-container">{render()}</div>
    </>
  );
}
