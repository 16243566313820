import { useState } from "react";
import { Button } from "react-bootstrap";
import { db } from "./Firebase";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { Link } from "react-router-dom";
const AddCareer= () => {
    const [Career, setCareer] = useState({
        jobTitle: "",
        description: "",
        isOpen:false,
    });
    const onChange = (e) => {
        setCareer({ ...Career, [e.target.name]: e.target.value });
    };

    async function handleSubmit(e) {
        e.preventDefault();
        if (
            Career.jobTitle === "" ||
            Career.description === "" 
        ) {
            alert("Please fill all the fields");
            return;
        }
        try {
            await addDoc(collection(db, "Careers"), {
                jobTitle: Career.jobTitle,
                description: Career.description,
                isOpen: Career.isOpen,
                created: Timestamp.now(),
            }).then(() => {
                alert("Career Added Successfully!!");
                setCareer({
                    jobTitle: "",
                    description: "",
                    isOpen: false,
                });
            });
        } catch (e) {
            alert(e);
            console.log(e);
        }
    }
    return (
        <div>
            <h1>Careers</h1>
            <form onSubmit={handleSubmit}>
                <label>Job Title</label>
                <textarea
                    type="text"
                    name="jobTitle"
                    value={Career.jobTitle || ""}
                    onChange={onChange}
                />

                <label>Description</label>
                <textarea
                    type="text"
                    name="description"
                    value={Career.description || ""}
                    onChange={onChange}
                />
                <label>Is Open</label>
                <select id="type" name="isOpen" value={Career.isOpen || "Select"} onChange={onChange}>
                    <option value="Select">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
                <Button
                    type="submit"
                    className="button"
                    variant="success"
                    onClick={handleSubmit}
                >
                    Add
                </Button>
                <p>Navigate Back - </p>
                <Link to="/admin/dashboard/Careers">Careers</Link>
            </form>
        </div>
    );
};

export default AddCareer;
