import { v4 as uuidv4 } from "uuid";

/* Handlers*/
export const handlelandmarkAdd = (project, list, setlist) => {
  const completeList = [...list];
  completeList.push("");
  setlist({ ...project, landmarks: completeList });
};
export const handlelandmarkRemove = ( project,index, list, setlist, handlelandmarkAdd) => {
  const completeList = [...list];
  completeList.splice(index, 1);
  setlist({ ...project, landmarks: completeList });
  if (project.landmarks.length === 1) {
    handlelandmarkAdd(project, project.landmarks, setlist);
  }
};
export const handlelandmarkChange = (e,  project,index, completeList, setlist) => {
  completeList[index] = e.target.value;
  setlist({ ...project, landmarks: completeList });
};

/*--------------------------------------------------*/

export const handleamentitiesAdd = (project, list, setlist) => {
  const completeList = [...list];
  completeList.push("");
  setlist({ ...project, amentities: completeList });
};
export const handleamentitiesRemove = ( project,index, list, setlist, handleamentitiesAdd) => {
  const completeList = [...list];
  completeList.splice(index, 1);
  setlist({ ...project, amentities: completeList });
  if (project.amentities.length === 1) {
    handleamentitiesAdd(project, project.amentities, setlist);
  }
};
export const handleamentitiesChange = (e,  project,index, completeList, setlist) => {
  completeList[index] = e.target.value;
  setlist({ ...project, amentities: completeList });
};

/*--------------------------------------------------*/
export const handlehighlightAdd = ( project,list, setlist) => {
  const completeList = [...list];
  completeList.push("");
  setlist({ ...project, highlight: completeList });
};
export const handlehighlightRemove = (
  project, index,
  list,
  setlist,
  handlehighlightAdd
) => {
  const completeList = [...list];
  completeList.splice(index, 1);
  setlist({ ...project, highlight: completeList });
  if (project.highlight.length === 0) {
    handlehighlightAdd(project, project.highlight, setlist);
  }
};
export const handlehighlightChange = (e, project, index, completeList, setlist) => {
  completeList[index] = e.target.value;
  setlist({ ...project, highlight: completeList });
};

/*--------------------------------------------------*/
export const handleflatsAdd = (project, list, setlist) => {
  const completeList = [...list];
  completeList.push({
    flatid: uuidv4(),
    title: "",
    description: "",
    type: "", image: "", imageUrl: "",
  });
  setlist({ ...project, flats: completeList });
};
export const handleflatsRemove = (
  project, index,
  list,
  setlist,
  handleflatsAdd
) => {
  const completeList = [...list];
  completeList.splice(index, 1);
  setlist({ ...project, flats: completeList });
  if (project.flats.length === 0) {
    handleflatsAdd(project, project.flats, setlist);
  }
};
export const handleflatsChange = (e, project, index, setlist) => {
  const flat = [ ...project.flats ];
  flat[index][e.target.name] = e.target.value;
  setlist({ ...project, flats : flat });
};
/*--------------------------------------------------*/
export const handletestimonialAdd = (project, list, setlist) => {
  const completeList = [...list];
  completeList.push({
    caption: "",
    paragraph: "",
    customer: "",
    occupation: "",
    display: "false",
  });
  setlist({ ...project, testimonial: completeList });
};
export const handletestimonialRemove = (
  project, index,
  list,
  setlist,
  handletestimonialAdd
) => {
  const completeList = [...list];
  completeList.splice(index, 1);
  setlist({ ...project, testimonial: completeList });
  if (project.testimonial.length === 0) {
    handletestimonialAdd(project, project.testimonial, setlist);
  }
};
export const handletestimonialChange = (e, project, index, setlist) => {
  const testimonialList = [ ...project.testimonial ];
  testimonialList[index][e.target.name] = e.target.value;
  setlist({ ...project, testimonial : testimonialList });
};
