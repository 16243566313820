import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom"
import {
    collection,
    query,
    getDocs,
} from "firebase/firestore";
import { Card, Button } from "react-bootstrap";
import { db } from "./Firebase";
import handleDelete from "./Funtions/CareersFn/DeleteCareer";
import handleEdit from "./Funtions/CareersFn/EditCareer";

const Careers = () => {
    const [allCareers, setallCareers] = useState([]);
    const [isEditing, setIsEditing] = useState(-1);
    const [isEditingInner, setIsEditingInner] = useState(-1);
    const [updatedCareer, setupdatedCareer] = useState({
        jobTitle: "",
        description: "",
        customer: "",
        occupation: "",
    });

    async function getCareers() {
        try {
            const reference = query(collection(db, "Careers"));
            const res = await getDocs(reference);
            const Images = res.docs.map((doc) => doc.data());
            const returnData = [
                Images.map((item) => {
                    return {
                        ...item,
                    };
                }),
            ];
            setallCareers(returnData);
        } catch (error) {
            console.log("error", error);
        }
    }
    useEffect(() => {
        getCareers();
    }, []);
    function render() {
        var index = 0;
        var innerIndex = 0;
        var globalIndex = 0;
        var returnArray = [];
        allCareers.forEach((item) => {
            innerIndex = 0;
            item.forEach((item1) => {
                returnArray.push(
                    returnCard(
                        (globalIndex = globalIndex),
                        (index = index),
                        (innerIndex = innerIndex),
                        (item = item1)
                    )
                );
                innerIndex++;
                globalIndex++;
            });
            index++;
        });
        return returnArray;
    }
    const onChange = (e) => {
        setupdatedCareer({
            ...updatedCareer,
            [e.target.name]: e.target.value,
        });
    };
    function returnCard(globalIndex, index, innerIndex, item) {
        return (
            <Card
                key={globalIndex}
                className="inline"
                style={{
                    width: "80vw",
                    display: "inline-block",
                }}
            >
                <Card.Body>
                    {isEditing === index && isEditingInner === innerIndex ? (
                        <input
                            type="text"
                            name="jobTitle"
                            value={
                                updatedCareer.jobTitle
                            }
                            onChange={onChange}
                        />
                    ) : (
                        <Card.Title>{item.jobTitle}</Card.Title>
                    )}

                    {isEditing === index && isEditingInner === innerIndex ? (
                        <textarea
                            type="text"
                            name="description"
                            value={
                                updatedCareer.description
                            }
                            onChange={onChange}
                        />
                    ) : (
                        <Card.Text>{item.description}</Card.Text>
                    )}
                    <br />
                    {isEditing === index && isEditingInner === innerIndex ? (
                        <select id="type" name="isOpen" value={updatedCareer.isOpen || "Select"} onChange={onChange}>
                            <option value="Select">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    ) : (
                        <Card.Text>{item.isOpen}</Card.Text>
                    )}
                    <br />
                </Card.Body>

                <Card.Body>
                    {isEditing !== index && isEditingInner !== innerIndex ? (
                        <Button
                            varint="primary"
                            className="button"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsEditingInner(innerIndex);
                                setIsEditing(index);
                                setupdatedCareer(item);
                            }}
                        >
                            Edit
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            className="button"
                            onClick={() => {
                                setIsEditingInner(-1);
                                setIsEditing(-1);
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                    {isEditing === index && isEditingInner === innerIndex ? (
                        <Button
                            className="button"
                            onClick={(e) => {
                                handleEdit(
                                    allCareers,
                                    updatedCareer,
                                    index,
                                    innerIndex,
                                    getCareers,
                                    setIsEditing
                                )
                            }}
                            variant="success"
                        >
                            Save
                        </Button>
                    ) : null}
                    <Button
                        className="button"
                        onClick={() =>
                            handleDelete(allCareers, index, innerIndex, getCareers)
                        }
                        variant="danger"
                    >
                        Delete
                    </Button>
                </Card.Body>
            </Card>
        );
    }
    return (
        <>
            <div className="career-admin">
                <Link to="/admin/dashboard/addCareer"><button className="addnew">
                    ++
                </button></Link>
                {render()}</div>
        </>
    );
};

export default Careers;
