import {
  collection,
  query,
  getDocs,
  where,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../Firebase";

async function handleDelete(
  allTestimonials,
  index,
  innerIndex,
  getTestimonials
) {
  const confirm = window.confirm(
    "Are you sure you want to delete this Testimonials?"
  );
  if (confirm) {
    try {
      const ref1 = collection(db, `Testimonials`);
      const q = query(
        ref1,
        where("caption", "==", allTestimonials[index][innerIndex].caption),
        where("customer", "==", allTestimonials[index][innerIndex].customer)
      );
      const response = await getDocs(q);
      const id = response.docs[0].id;
      await deleteDoc(doc(db, `Testimonials`, `${id}`))
        .then(() => {
          getTestimonials();
          alert("Testimonials deleted successfully");
        })
        .catch((err) => {
          alert(err);
        });
    } catch (err) {
      console.log(err);
    }
  } else {
    alert("Testimonials not deleted");
  }
}

export default handleDelete;
