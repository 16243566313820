import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom"
import { collection, query, getDocs } from "firebase/firestore";
import { Card, Button } from "react-bootstrap";
import { db } from "./Firebase";
import handleProjectEdit from "./Funtions/ProjectsFn/EditProject";
import handleProjectDelete from "./Funtions/ProjectsFn/DeleteProjects";
import {
  handlehighlightAdd,
  handlehighlightChange,
  handlehighlightRemove,
  handlelandmarkAdd,
  handlelandmarkChange,
  handlelandmarkRemove,
  handletestimonialAdd,
  handletestimonialChange,
  handletestimonialRemove,
  handleflatsAdd,
  handleflatsChange,
  handleflatsRemove,
  handleamentitiesChange,
  handleamentitiesAdd,
  handleamentitiesRemove,
} from "./Funtions/ProjectHandlers";
const ProjectsAdmin = () => {
  const [Projects, setProjects] = useState([]);

  const [isEditing, setIsEditing] = useState();
  const [isEditingInner, setIsEditingInner] = useState();
  const [updatedProjects, setupdatedProjects] = useState({
    name: "",
    description: "",
    brochureLink:"",
    type: "Select",
    mainproject: "false",
    location: "",
    reraNumber: "",
    latitude: 0,
    longitude: 0,
    mainimage:"",
    landmarks: [],
    highlight: [],
    flats: [],
    testimonial: [],
  });

  async function getProjects() {
    try {
      const q1 = query(collection(db, `Projects-Ongoing`));
      const q2 = query(collection(db, `Projects-Upcoming`));
      const q3 = query(collection(db, `Projects-Completed`));
      const res1 = await getDocs(q1);
      const res2 = await getDocs(q2);
      const res3 = await getDocs(q3);
      const ongoingData = res1.docs.map((doc) => {
        return { data: doc.data(), id: doc.id };
      });
      const upcomingData = res2.docs.map((doc) => {
        return { data: doc.data(), id: doc.id };
      });
      const completedData = res3.docs.map((doc) => {
        return { data: doc.data(), id: doc.id };
      });

      var returnOngoingData = [
        ...ongoingData.map((item) => {
          return {
            ...item.data,
            // flats: [],
            // id: item.id,
          };
        }),
      ];
      var returnUpcomingData = [
        ...upcomingData.map((item) => {
          return {
            ...item.data,
            // flats: [],
            // id: item.id,
          };
        }),
      ];
      var returnCompletedData = [
        ...completedData.map((item) => {
          return {
            ...item.data,
            // flats: [],
            // id: item.id,
          };
        }),
      ];

      // try {
      //   for (let i = 0; i < returnOngoingData.length; i++) {
      //     const r1 = collection(
      //       db,
      //       `Projects-Ongoing/${returnOngoingData[i].id}/Flats`
      //     );
      //     const q1 = query(r1);
      //     const response1 = await getDocs(q1);
      //     const Data1 = response1.docs.map((doc) => doc.data());
      //     returnOngoingData[i].flats.push(
      //       ...Data1.map((item) => {
      //         return {
      //           ...item,
      //         };
      //       })
      //     );
      //   }
      //   for (let i = 0; i < returnUpcomingData.length; i++) {
      //     const r1 = collection(
      //       db,
      //       `Projects-Upcoming/${returnUpcomingData[i].id}/Flats`
      //     );
      //     const q1 = query(r1);
      //     const response1 = await getDocs(q1);
      //     const Data1 = response1.docs.map((doc) => doc.data());
      //     returnUpcomingData[i].flats.push(
      //       ...Data1.map((item) => {
      //         return {
      //           ...item,
      //         };
      //       })
      //     );
      //   }
      //   for (let i = 0; i < returnCompletedData.length; i++) {
      //     const r1 = collection(
      //       db,
      //       `Projects-Completed/${returnCompletedData[i].id}/Flats`
      //     );
      //     const q1 = query(r1);
      //     const response1 = await getDocs(q1);
      //     const Data1 = response1.docs.map((doc) => doc.data());
      //     returnCompletedData[i].flats.push(
      //       ...Data1.map((item) => {
      //         return {
      //           ...item,
      //         };
      //       })
      //     );
      //   }
        var returnData = [
          returnOngoingData, returnUpcomingData, returnCompletedData
        ];
        setProjects(returnData);
      // } catch (err) {
      //   console.log(err);
      // }
    } catch (error) {
      console.log("error", error);
    }
  }
  useEffect(() => {
    getProjects();
  }, []);
  function render() {
    var index = 0;
    var innerIndex = 0;
    var globalIndex = 0;
    var returnArray = [];
    Projects.forEach((item) => {
      innerIndex = 0;
      item.forEach((item1) => {
        returnArray.push(
          returnCard(
            (globalIndex = globalIndex),
            (index = index),
            (innerIndex = innerIndex),
            (item = item1)
          )
        );
        innerIndex++;
        globalIndex++;
      });
      index++;
    });
    return returnArray;
  }
  const onChange = (e) => {
    setupdatedProjects({
      ...updatedProjects,
      [e.target.name]: e.target.value,
    });
  };
  function returnCard(globalIndex, index, innerIndex, item) {
    return (
      <Card
        key={globalIndex}
        className="inline"
        style={{
          width: "80vw",
          height: "fit-contents",
          display: "inline-block",
        }}
      >
        <Card.Body>
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="text"
              name="name"
              value={updatedProjects.name || ""}
              onChange={onChange}
            />
          ) : (
            <Card.Title>{item.name}</Card.Title>
          )}
          {isEditing === index && isEditingInner === innerIndex ? (
            <div>
              <label>Project Picture</label>
              <input
                type="file"
                className="itemList-input"
                name="mainimage"
                onChange={(e) => {
                  setupdatedProjects({ ...updatedProjects, mainimage: e.target.files[0] });
                }
                }/>
            </div>
          ) : (
              <><img src={item.mainimageUrl} className="project-images" alt=""/></>
          )}
          <br />

          {isEditing === index && isEditingInner === innerIndex ? (
            <textarea
              type="text"
              name="description"
              value={updatedProjects.description || ""}
              onChange={onChange}
            />
          ) : (
            <Card.Text>{item.description}</Card.Text>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="text"
              name="brochureLink"
              value={updatedProjects.brochureLink || ""}
              onChange={onChange}
            />
          ) : (
              <a href={item.brochureLink} target="_black"><Button> View Brochure</Button></a>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <div>
              <label>Project Type</label>
              <select id="type" name="type" value={updatedProjects.type} onChange={onChange}>
                <option value="Select">Select</option>
                <option value="Completed">Completed</option>
                <option value="Ongoing">Ongoing</option>
                <option value="Upcoming">Upcoming</option>
              </select>
            </div>
          ) : (
            <>{item.type}</>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <div>
              <label>Want to highlight this project?</label>
              <select id="mainproject" name="mainproject" value={updatedProjects.mainproject || ""} onChange={onChange}>
                <option value="Select">Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          ) : (
              <>{item.mainproject}</>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="text"
              name="reraNumber"
              value={updatedProjects.reraNumber || ""}
              onChange={onChange}
            />
          ) : (
              <Card.Text>{item.reraNumber}</Card.Text>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="text"
              name="location"
              value={updatedProjects.location || ""}
              onChange={onChange}
            />
          ) : (
            <Card.Text>{item.location}</Card.Text>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="number"
              name="latitude"
              value={updatedProjects.latitude || ""}
              onChange={onChange}
            />
          ) : (
            <Card.Text>{item.latitude}</Card.Text>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <input
              type="number"
              name="longitude"
              value={updatedProjects.longitude}
              onChange={onChange}
            />
          ) : (
            <Card.Text>{item.longitude}</Card.Text>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <div>
              <table className="item__table">
                <thead>
                  <tr className="item-heading">
                    <th className="title-item--index">#</th>
                    <th className="title-item title">landmarks</th>
                  </tr>
                </thead>
                <tbody>
                  {updatedProjects.landmarks?.map((x, i) => {
                    return (
                      <tr key={i} className="item-row">
                        <td className="title-input invoice__item--index">
                          {/* {index === i ? */}
                          <div
                            onClick={() => {
                              handlelandmarkRemove(
                                updatedProjects,
                                i,
                                updatedProjects.landmarks,
                                setupdatedProjects,
                                handlelandmarkAdd(
                                  updatedProjects,
                                  updatedProjects.landmarks,
                                  setupdatedProjects
                                )
                              );
                            }}
                          >
                            <i className="fa-solid fa-trash-can">--</i>
                          </div>
                          {/* : <div>
                        {i}
                      </div>
                    } */}
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            value={updatedProjects.landmarks[i]}
                            onChange={(e) =>
                              handlelandmarkChange(
                                e,
                                updatedProjects,
                                i,
                                updatedProjects.landmarks,
                                setupdatedProjects
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="itemAdd-btn">
                {updatedProjects.landmarks?.lenght !== 1 && (
                  <Button
                    onClick={() => {
                      handlelandmarkAdd(
                        updatedProjects,
                        updatedProjects.landmarks,
                        setupdatedProjects
                      );
                    }}
                  >
                    Add landmark
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
              <h3>Landmarks</h3>
              {item.landmarks?.map((landmark) => {
                return <div>{landmark}</div>;
              })}
            </>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <div>
              <table className="item__table">
                <thead>
                  <tr className="item-heading">
                    <th className="title-item--index">#</th>
                    <th className="title-item title">Distance</th>
                  </tr>
                </thead>
                <tbody>
                  {updatedProjects.highlight?.map((x, i) => {
                    return (
                      <tr key={i} className="item-row">
                        <td className="title-input invoice__item--index">
                          {/* {index === i ? */}
                          <div
                            onClick={() => {
                              handlehighlightRemove(
                                updatedProjects,
                                i,
                                updatedProjects.highlight,
                                setupdatedProjects,
                                handlehighlightAdd(
                                  updatedProjects,
                                  updatedProjects.highlight,
                                  setupdatedProjects
                                )
                              );
                            }}
                          >
                            <i className="fa-solid fa-trash-can">--</i>
                          </div>
                          {/* : <div>
                        {i}
                      </div>
                    } */}
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            value={updatedProjects.highlight[i]}
                            onChange={(e) =>
                              handlehighlightChange(
                                e,
                                updatedProjects,
                                i,
                                updatedProjects.highlight,
                                setupdatedProjects
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="itemAdd-btn">
                {updatedProjects.highlight?.lenght !== 1 && (
                  <Button
                    onClick={() => {
                      handlehighlightAdd(
                        updatedProjects,
                        updatedProjects.highlight,
                        setupdatedProjects
                      );
                    }}
                  >
                    Add Distance
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
                <h3>Distance</h3>
              {item.highlight?.map((para) => {
                return <div>{para}</div>;
              })}
            </>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <div>
              <table className="item__table">
                <thead>
                  <tr className="item-heading">
                    <th className="title-item--index">#</th>
                    <th className="title-item title">Amentities</th>
                  </tr>
                </thead>
                <tbody>
                  {updatedProjects.amentities?.map((x, i) => {
                    return (
                      <tr key={i} className="item-row">
                        <td className="title-input invoice__item--index">
                          {/* {index === i ? */}
                          <div
                            onClick={() => {
                              handleamentitiesRemove(
                                updatedProjects,
                                i,
                                updatedProjects.amentities,
                                setupdatedProjects,
                                handleamentitiesAdd(
                                  updatedProjects,
                                  updatedProjects.amentities,
                                  setupdatedProjects
                                )
                              );
                            }}
                          >
                            <i className="fa-solid fa-trash-can">--</i>
                          </div>
                          {/* : <div>
                        {i}
                      </div>
                    } */}
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            value={updatedProjects.amentities[i]}
                            onChange={(e) =>
                              handleamentitiesChange(
                                e,
                                updatedProjects,
                                i,
                                updatedProjects.amentities,
                                setupdatedProjects
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="itemAdd-btn">
                {updatedProjects.amentities?.lenght !== 1 && (
                  <Button
                    onClick={() => {
                      handleamentitiesAdd(
                        updatedProjects,
                        updatedProjects.amentities,
                        setupdatedProjects
                      );
                    }}
                  >
                    Add amentities
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
              <h3>Amentities</h3>
              {item.amentities?.map((amentities) => {
                return <div>{amentities}</div>;
              })}
            </>
          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <div>
              <table className="item__table">
                <thead>
                  <tr className="item-heading">
                    <th className="title-item--index">#</th>
                    <th className="title-item title">Caption</th>
                    <th className="title-item title">Paragraph</th>
                    <th className="title-item title">Customer</th>
                    <th className="title-item title">Occupation</th>
                  </tr>
                </thead>
                <tbody>
                  {updatedProjects.testimonial?.map((x, i) => {
                    return (
                      <tr key={i} className="item-row">
                        <td className="title-input invoice__item--index">
                          {/* {index === i ? */}
                          <div
                            onClick={() => {
                              handletestimonialRemove(
                                updatedProjects,
                                i,
                                updatedProjects.testimonial,
                                setupdatedProjects,
                                handletestimonialAdd(
                                  updatedProjects,
                                  updatedProjects.testimonial,
                                  setupdatedProjects
                                )
                              );
                            }}
                          >
                            <i className="fa-solid fa-trash-can">--</i>
                          </div>
                          {/* : <div>
                        {i}
                      </div>
                    } */}
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            name="caption"
                            value={updatedProjects.testimonial[i].caption}
                            onChange={(e) =>
                              handletestimonialChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          />
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            name="paragraph"
                            value={updatedProjects.testimonial[i].paragraph}
                            onChange={(e) =>
                              handletestimonialChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          />
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            name="customer"
                            value={updatedProjects.testimonial[i].customer}
                            onChange={(e) =>
                              handletestimonialChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          />
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            name="occupation"
                            value={updatedProjects.testimonial[i].occupation}
                            onChange={(e) =>
                              handletestimonialChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          />
                        </td>
                        <td className="title-input">
                          <select
                            name="display"
                            value={updatedProjects.testimonial[i].display}
                            onChange={(e) =>
                              handletestimonialChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          >
                            <option value="Select">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="itemAdd-btn">
                {updatedProjects.testimonial?.lenght !== 1 && (
                  <Button
                    onClick={() => {
                      handletestimonialAdd(
                        updatedProjects,
                        updatedProjects.testimonial,
                        setupdatedProjects
                      );
                    }}
                  >
                    Add testimonial
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
              <h3>Testimonials</h3>
              {item.testimonial?.map((x) => {
                return (
                  <>
                    <p>{x.caption}</p>
                    <p>{x.paragraph}</p>
                    <p>{x.customer}</p>
                    <p>{x.occupation}</p>
                  </>
                )
              })}
            </>

          )}
          <br />
          {isEditing === index && isEditingInner === innerIndex ? (
            <div>
              <table className="item__table">
                <thead>
                  <tr className="item-heading">
                    <th className="title-item--index">#</th>
                    <th className="title-item title">title</th>
                    <th className="title-item title">description</th>
                    <th className="title-item title">Type</th>
                    <th className="title-item title">Image</th>
                  </tr>
                </thead>
                <tbody>
                  {updatedProjects.flats?.map((x, i) => {
                    return (
                      <tr key={i} className="item-row">
                        <td className="title-input invoice__item--index">
                          {/* {index === i ? */}
                          <div
                            onClick={() => {
                              handleflatsRemove(
                                updatedProjects,
                                i,
                                updatedProjects.flats,
                                setupdatedProjects,
                                handleflatsAdd(
                                  updatedProjects,
                                  updatedProjects.flats,
                                  setupdatedProjects
                                )
                              );
                            }}
                          >
                            <i className="fa-solid fa-trash-can">--</i>
                          </div>
                          {/* : <div>
                        {i}
                      </div>
                    } */}
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            name="title"
                            value={updatedProjects.flats[i].title}
                            onChange={(e) =>
                              handleflatsChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          />
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            name="description"
                            value={updatedProjects.flats[i].description}
                            onChange={(e) =>
                              handleflatsChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          />
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            name="type"
                            value={updatedProjects.flats[i].type}
                            onChange={(e) =>
                              handleflatsChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          />
                        </td>
                        <td className="title-input">
                          <input
                            className="itemList-input"
                            type="file"
                            name="image"
                            onChange={(e) => {
                              const flat = [...updatedProjects.flats];
                              flat[i][e.target.name] = e.target.files[0];
                              setupdatedProjects({ ...updatedProjects, flats: flat });
                            }
                            }
                          />
                        </td>
                        {/* <td className="title-input">
                          <select
                            name="display"
                            value={updatedProjects.flats[i].display}
                            onChange={(e) =>
                              handleflatsChange(e, updatedProjects, i, setupdatedProjects)
                            }
                          >
                            <option value="Select">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="itemAdd-btn">
                {updatedProjects.flats?.lenght !== 1 && (
                  <Button
                    onClick={() => {
                      handleflatsAdd(
                        updatedProjects,
                        updatedProjects.flats,
                        setupdatedProjects
                      );
                    }}
                  >
                    Add flats
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <>
              <h3>Flats</h3>
              {item.flats?.map((x) => {
                return (
                  <>
                    <p>Flat Title : {x.title}</p>
                    <img src={x.imageUrl} alt="" className="flatimage"/>
                    <p>Description : {x.description}</p>
                    <p> Flat Type: {x.type}</p>
                  </>
                )
              })}
            </>
          )}
          <br />
        </Card.Body>

        <Card.Body>
          {isEditing !== index && isEditingInner !== innerIndex ? (
            <Button
              varint="primary"
              className="button"
              onClick={(e) => {
                e.preventDefault();
                setIsEditingInner(innerIndex);
                setIsEditing(index);
                setupdatedProjects(item)
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              variant="primary"
              className="button"
              onClick={() => {
                setIsEditingInner(-1);
                setIsEditing(-1);
              }}
            >
              Cancel
            </Button>
          )}
          {isEditing === index && isEditingInner === innerIndex ? (
            <Button
              className="button"
              onClick={() => {
                handleProjectEdit(
                  Projects,
                  updatedProjects,
                  setupdatedProjects,
                  index,
                  innerIndex,
                  getProjects,
                  setIsEditing
                );
                console.log(updatedProjects)
              }}
              variant="success"
            >
              Save
            </Button>
          ) : null}
          <Button
            className="button"
            onClick={() => {
              handleProjectDelete(Projects, index, innerIndex, getProjects);
            }}
            variant="danger"
          >
            Delete
          </Button>
        </Card.Body>
      </Card>
    );
  }
  return (
    <>
      <div className="projectsAdmin">
        <Link to="/admin/dashboard/addprojects"><button className="addnew">
          ++
        </button></Link>
        {render()}
      </div>
    </>
  );
};

export default ProjectsAdmin;
